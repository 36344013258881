<script setup>
import { inject, ref, onMounted, computed, onBeforeMount } from 'vue';
import SddsDropdown from '../components/SddsDropdown.vue';
import VehicleCard from '../components/VehicleCard.vue';
import PageSpinner from '../components/PageSpinner.vue';
import ChipFilters from '../components/ChipFilters.vue';
import {
  addTrackingEvent,
  getSalesmanOfferings,
  getActiveOffersSummary,
} from '../services/digitalSalesService.js';
import { useMainStore } from '../store/index.js';
import { formatIsoStringToLocalDateTime } from '../utils/timeFormatting.js';

const adobeEvent = inject('adobeEvent');
const store = useMainStore();

onBeforeMount(() => {
  store.activePage = 'ACTIVE_OFFERS';
  store.showYearEndOfferBanner = false;
});

const registerVatPageUrl = process.env.VUE_APP_DS_REGISTER_VAT_URL;

const isLoading = ref(false);
const showError = ref(false);
const availableSalesmanOfferings = ref([]);
const selectedCompany = ref(null);
const selectedCompanyVatNip = ref(null);
const selectedOffering = ref(null);
const selectedOfferingId = ref(null);
const isCompanySelectionLoading = ref(false);
const isOfferingSelectionLoading = ref(false);
const showYearEndOfferInline = ref(false);
const showYear2024OfferInline = ref(false);
const showEcolutionBlob = ref(false);

const availableFilters = ref([]);
const selectedFilters = ref({});
const availableOffers = ref({});


const toggleFilter = (filter, header) => {
  if (selectedFilters.value[header].includes(filter)) {
    selectedFilters.value[header] = selectedFilters.value[header].filter((f) => f !== filter);
  } else {
    selectedFilters.value[header].push(filter);
  }
  adobeEvent({
    event: 'toggleFilter',
    filterInfo: {
      filterName: filter,
      filterAction: selectedFilters.value[header].includes(filter) ? 'add' : 'remove',
    },
  });
};

const sendTracking = () => {
  adobeEvent({
    event: 'pageView',
    page: {
      pageName: 'AvailableOffersView',
    },
  });
  if (store.tid) {
    const data = {
      event: 'availableOffersView',
      data: { timestamp: new Date().toISOString() },
    };
    addTrackingEvent(data, store.tid)
      .then(() => {
        store.tid = null;
      })
      .catch((err) => {
        console.error('failed to create tracking event', err);
      });
  }
};

const fetchOfferConfigurationImages = async () => {
  let offers = {};
  try {
    if (store.isSalesman) {
      offers = (await getActiveOffersSummary(selectedOffering.value?.offeringId)).data;
    } else {
      offers = (await getActiveOffersSummary()).data;
    }
  } catch (error) {
    if (error.response?.status === 404) {
      console.warn('No offers returned from backend');
    }
    else {
      console.error('Failed getActiveOffersSummary', error);
      throw error;
    }
  }

  let frameAgreementAvailable = false;
  const filterMap = new Map();

  Object.entries(offers).forEach(([specTypeKey, innerOffers]) => {

    const transformedOffers = innerOffers.map(
      ({
        offerId,  
        misc, 
        expired, 
        finalized, 
        isOfferNotValid = false, 
        createdDate, 
        sortOrder, 
        imageSpecificationAlt,
        filters,
      }) => {
        const getOfferValidDate = () => {
          if (!misc?.offerValidUntilDate) return [];
          const date = new Date(misc.offerValidUntilDate);
          return [`${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getFullYear()).substring(2)}`];
        };

        filters?.forEach(({ header, tags }) => {
          if (!filterMap.has(header)) {
            filterMap.set(header, []);
          }
          filterMap.get(header).push(...tags);
        });

        const checkIfStockSpec = () => misc?.specType && misc.specType === 'stock_vehicle';

        // Flag that a frame agreement is available among the offers
        if (misc?.frameAgreement) {
          frameAgreementAvailable = true;
        }

        return {
          offerId,
          createdDate,
          sortOrder,
          title: misc?.vehicleCardHighlightsHeader,
          title2: misc?.vehicleCardHighlightsHeader2,
          imageUrl: 'https://cdn.digitaldesign.scania.com/icons/website_hosted/truck.svg',
          additionalInfo: misc?.vehicleCardAdditionalInfo || null,
          specialOfferBannerHeader: misc?.vehicleCardSpecialOfferHeader,
          specialOfferBannerDescription: misc?.vehicleCardSpecialOfferDescription,
          keyPoints: [
            {
              icon: 'tick',
              translationKey: misc?.vehicleCardHighlightsText1,
            },
            {
              icon: 'tick',
              translationKey: misc?.vehicleCardHighlightsText2,
            },
            {
              icon: 'tick',
              translationKey: misc?.vehicleCardHighlightsText3,
            },
          ],
          offerStatus: { expired, finalized, isOfferNotValid },
          offerData: { offerValidDate: getOfferValidDate() },
          misc,
          imageSpecificationAlt,
          filters: filters?.map(({ header, tags }) => ({
            header,
            tags: tags.map((t) => t.label),
          })),
          stockVehicle: !!checkIfStockSpec(),
          frameAgreement: misc?.frameAgreement,
          tailorMade: !checkIfStockSpec(),
        };
      },
    );
    availableOffers.value[specTypeKey] = transformedOffers;
  });

  store.frameAgreementAvailable = frameAgreementAvailable;

  availableFilters.value = Array.from(filterMap.entries()).map(
      ([header, tagObjects]) => {
      const tagsWithGroup = tagObjects.filter((tagObj) => tagObj.group);
      const sortedTags = tagsWithGroup.sort((a, b) => {
        const groupCompare = a.group.localeCompare(b.group);
        return groupCompare !== 0 ? groupCompare : a.label.localeCompare(b.label);
      });
      const labelsOnly = sortedTags.map((t) => t.label);
      const uniqueLabels = [...new Set(labelsOnly)];

      return {
        header,
        tags: uniqueLabels,
      };
    },
  );
  selectedFilters.value = Object.fromEntries(
    availableFilters.value.map(({ header }) => [header, []]),
  );
};

const fetchSalesmanOfferings = async () => {
  try {
    const response = await getSalesmanOfferings();

    availableSalesmanOfferings.value = Object.values(response.data).map(company => {
      return {
        ...company,
        offerings: company.offerings.map(offering => ({
          ...offering,
          createdDate: formatIsoStringToLocalDateTime(offering.createdDate, store.locale),
          offerValidUntilDate: formatIsoStringToLocalDateTime(offering.offerValidUntilDate, store.locale),
        })),
      };
    });
    selectedCompany.value = availableSalesmanOfferings.value[0];
    selectedCompanyVatNip.value = selectedCompany.value?.vatNip;
    selectedOffering.value = selectedCompany.value.offerings[0];
    selectedOfferingId.value = selectedOffering.value.offeringId;
  } catch (err) {
    console.error('error fetching salesman offerings: ', err);
    showError.value = true;
  }
};


const setFeatureToggle = async () => {
  store.showYearEndOfferBanner = false;
  if (availableOffers.value.length > 0) {
    const hasFrameAgreement = availableOffers.value.some((aO) => aO.frameAgreement);
    const hasStockVehicle = availableOffers.value.some((aO) => aO.stockVehicle && !aO.frameAgreement);
    const hasTailorMade = availableOffers.value.some((aO) => aO.tailorMade && !aO.frameAgreement);
    const hasOnlyStockVehicle = hasStockVehicle && !hasFrameAgreement && !hasTailorMade;

    if (hasStockVehicle) {
      const yearEndToggle = store.featureToggleData[`${store.market}#webapp_yearEndOfferBanner`];
      if (yearEndToggle) {
        const { isActive, isEffectiveUser, isFeatureDateValid } = yearEndToggle;
        const showYearEndOffer = isActive && isEffectiveUser && isFeatureDateValid;
        if (hasOnlyStockVehicle) store.showYearEndOfferBanner = showYearEndOffer;
        else showYearEndOfferInline.value = showYearEndOffer;
      }
    }

    const ecolutionToggle = store.featureToggleData[`${store.market}#webapp_show_ecolutionBlob`];
    if (ecolutionToggle) showEcolutionBlob.value = ecolutionToggle.isActive;
  }
};


const applySelectedFilters = (offer) => {
  for (const [header, selectedTags] of Object.entries(selectedFilters.value)) {
    if (selectedTags.length === 0) continue;
    const filterObj = offer.filters?.find((f) => f.header === header);
    if (!filterObj || selectedTags.some((tag) => !filterObj.tags.includes(tag))) {
      return false;
    }
  }
  return true;
};


const hasAnyOffers = computed(() => Object.values(availableOffers.value).some(array => array.length > 0));
const hasAnyFilteredOffers = computed(() => dynamicOffersGroups.value.some((group) => group.offers.length > 0));

const dynamicOffersGroups = computed(() => {
  return Object.entries(availableOffers.value).map(([specTypeKey, offersArray], index) => {
    const filteredOffers = offersArray.filter((offer) => applySelectedFilters(offer));

    return {
      id: index + 1,
      title: specTypeKey,
      text: `${specTypeKey}Text`,
      offers: filteredOffers,
      cardHeaderChips: { specialOffer: showYearEndOfferInline.value || showYear2024OfferInline.value, ecolution: showEcolutionBlob.value }, // if apply Emil wants to have for all type vehicles,
    };
  });
});


const handleCompanySelection = async (companyVatNip) => {
  isCompanySelectionLoading.value = true;
  selectedCompanyVatNip.value = companyVatNip;
  selectedCompany.value = availableSalesmanOfferings.value.find((company) => company.vatNip === companyVatNip);
  selectedOffering.value = selectedCompany.value.offerings[0];
  selectedOfferingId.value = selectedOffering.value.offeringId;
  await handleOfferingSelection(selectedOfferingId.value);
  isCompanySelectionLoading.value = false;
};

const handleOfferingSelection = async (offeringId) => {
  isOfferingSelectionLoading.value = true;
  selectedOffering.value = selectedCompany.value.offerings.find(offering => offering.offeringId === offeringId);
  selectedOfferingId.value = offeringId;
  await fetchOfferConfigurationImages();
  isOfferingSelectionLoading.value = false;
};


onMounted(async () => {
  isLoading.value = true;
  sendTracking();
  if (store.isSalesman) await fetchSalesmanOfferings();
  await fetchOfferConfigurationImages();
  await setFeatureToggle();
  isLoading.value = false;
});

</script>
<template>
  <template v-if="isLoading">
    <PageSpinner />
  </template>
  <template v-else>
    <div class="active-offers__container">
      <div class="active-offers__header sdds-u-relative">
        <div>
          <p class="sdds-paragraph-01">
            {{ $i18n('availableOffersHeroTitle') }}
          </p>
          <p class="sdds-expressive-headline-01">
            {{ $i18n('availableOffersHeroHeader') }}
          </p>
          <p class="sdds-paragraph-02">
            {{ $i18n('availableOffersHeroBody') }}
          </p>
        </div>
        <template v-if="showEcolutionBlob">
          <div class="ecolution-blob">
            <h2 class="sdds-u-m0">
              {{ $i18n('availableOffersOfferTitle') }}
            </h2>
            <p class="sdds-u-m0">
              {{ $i18n('availableOffersOfferDescription') }}
            </p>
          </div>
        </template>
      </div>
      <template v-if="!hasAnyOffers">
        <div class="no-active-offers sdds-u-mt3">
          <h1>{{ $i18n('noActiveOffers') }}</h1>
          <p>{{ $i18n('noActiveOffersText') }}</p>
          <a
            class="sdds-btn sdds-btn-primary"
            :href="registerVatPageUrl"
          >
            {{ $i18n('renewOffersButton') }}
          </a>
        </div>
      </template>
      <div
        class="active-offers__main-content is-there-blob"
      >
        <template v-if="store.isSalesman">
          <div>
            <p class="sdds-headline-01">
              {{ $i18n('availableSalesmanOffersBodyHeading') }}
            </p>
            <p class="sdds-paragraph-02">
              {{ $i18n('availableSalesmanOffersBodyText') }}
            </p>
          </div>
          <div v-if="availableSalesmanOfferings">
            <div class="salesman-offerings">
              <div class="sdds-row">
                <div class="sdds-col-xs-12 sdds-col-sm-12 sdds-col-md-12 sdds-col-lg-4 sdds-u-pl0">
                  <div class="sdds-col-12">
                    <label
                      for="companyDropdown"
                      class="sdds-headline-02"
                    >
                      {{ $i18n('availableSalesmanOffersTitleCustomer') }}
                    </label>
                    <SddsDropdown
                      id="companyDropdown"
                      :selection="selectedCompanyVatNip"
                      :options="availableSalesmanOfferings"
                      value-key="vatNip"
                      option-key="companyName"
                      class="sdds-u-pt2"
                      @update:selection="handleCompanySelection"
                    />
                  </div>
                  <div
                    v-if="selectedCompany?.offerings"
                    class="sdds-col-12 sdds-u-mt3"
                  >
                    <label
                      for="offeringDropdown"
                      class="sdds-headline-02"
                    >
                      {{ $i18n('Offerings (needs translation key)') }}
                    </label>
                    <SddsDropdown
                      id="offeringDropdown"
                      :selection="selectedOfferingId"
                      :options="selectedCompany.offerings"
                      value-key="offeringId"
                      option-key="createdDate"
                      class="sdds-u-pt2"
                      @update:selection="handleOfferingSelection"
                    />
                  </div>
                </div>
                <div
                  v-if="selectedOffering"
                  class="sdds-col-xs-12 sdds-col-sm-12 sdds-col-md-12 sdds-col-lg-8 sdds-u-pr0"
                >
                  <div class="company-information">
                    <div class="sdds-row">
                      <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                        {{ $i18n('availableSalesmanOffersTitleCompany') }}
                      </div>
                      <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                        {{ selectedCompany.companyName }}
                      </div>
                    </div>
                    <div class="sdds-row sdds-u-pt2">
                      <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                        {{ $i18n('availableSalesmanOffersTitleContactName') }}
                      </div>
                      <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                        {{ selectedOffering.customerName }}
                      </div>
                    </div>
                    <div class="sdds-row sdds-u-pt2">
                      <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                        {{ $i18n('availableSalesmanOffersTitleContactEmail') }}
                      </div>
                      <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                        {{ selectedOffering.customerEmail }}
                      </div>
                    </div>
                    <div class="sdds-row sdds-u-pt2">
                      <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                        {{ $i18n('availableSalesmanOffersTitleVat') }}
                      </div>
                      <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                        {{ selectedCompany.vatNip }}
                      </div>
                    </div>
                    <div class="sdds-row sdds-u-pt2">
                      <div class="sdds-col-xs-12 sdds-col-sm-4 sdds-headline-04">
                        {{ $i18n('availableSalesmanOffersTitleClassification') }}
                      </div>
                      <div class="sdds-col-xs-12 sdds-col-sm-8 sdds-paragraph-02">
                        {{ selectedCompany.customerClassification }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="isOfferingSelectionLoading">
          <div class="spinner fa-4x">
            <font-awesome-icon
              icon="spinner"
              class="fa-spin"
            />
          </div>
        </template>
        <template v-else>
          <template v-if="availableFilters.length">
            <p class="sdds-headline-02">
              {{ $i18n('availableOffersFilter') }}
            </p>
            <div
              v-for="{ header, tags } in availableFilters"
              :key="header"
              class="sdds-paragraph-02"
            >
              <p class="sdds-paragraph-02">
                {{ $i18n(header) }}
              </p>
              <ChipFilters
                :options="tags.map(tag => ({ tag }))"
                :selected-options="selectedFilters[header]"
                id-field="tag"
                name-field="tag"
                @click="toggleFilter($event, header)"
              />
            </div>
          </template>
          <template v-if="hasAnyFilteredOffers">
            <template
              v-for="group in dynamicOffersGroups"
              :key="group.id"
            >
              <div v-if="group.offers.length">
                <div>
                  <p class="sdds-headline-02 sdds-u-mb0">
                    {{ $i18n(group.title) }}
                  </p>
                  <p class="sdds-paragraph-03 sdds-u-mb0">
                    {{ $i18n(group.text) }}
                  </p>
                </div>
                <div class="active-offers__card-list sdds-u-mt0 sdds-u-mb3">
                  <VehicleCard
                    v-for="card in group.offers"
                    :key="card.offerId"
                    :offer-id="card.offerId"
                    :title="card.title"
                    :title2="card.title2"
                    :keypoints="card.keyPoints"
                    :image-url="card.imageUrl"
                    :alt-image="card.imageSpecificationAlt?.activeOffersPage"
                    :offer-status="card.offerStatus"
                    :offer-data="card.offerData"
                    :card-header-chips="group.cardHeaderChips"
                    :card-chips="card.misc.cardChips || {}"
                    :additional-info="card.additionalInfo"
                    :special-offer-banner-header="card.specialOfferBannerHeader"
                    :special-offer-banner-description="card.specialOfferBannerDescription"
                  />
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="no-filtered-offers">
              <h1>{{ $i18n('noFilteredOffers') }}</h1>
              <p>{{ $i18n('noFilteredOffersText') }}</p>
            </div>
          </template>
        </template>
      </div>
    </div>
  </template>
</template>

<style scoped>
.sdds-expressive-headline-01 {
  margin: unset;
  line-height: 1.2;
}
.active-offers__header > div > .sdds-paragraph-01 {
  margin: 0;
  padding: 0;
}
.active-offers__card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--sdds-spacing-element-32);
  width: 100%;
  margin-top: var(--sdds-spacing-element-16);
}
.active-offers__card-list > * {
  width: calc(33% - var(--sdds-spacing-element-32));
}
.active-offers__container {
  display: flex;
  flex-direction: column;
}
.active-offers__header {
  background-image: url('@/assets/active-offers-bg.jpeg');
  background-repeat: no-repeat;
  background-position: 60% 60%;
  background-size: cover;
  min-height: 40vh;
  color: white;
}
.active-offers__header > div {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: var(--sdds-spacing-element-24) var(--sdds-spacing-element-48);
}
.ecolution-blob {
  position: absolute;
  display: inline-block;
  right: 150px;
  bottom: -130px;
  width: 228px !important;
  height: 228px !important;
  border-radius: 50%;
  border: 2px solid #C84A07F2;
  background: linear-gradient(0deg, rgba(200, 74, 7, 0.95), rgba(200, 74, 7, 0.95)), linear-gradient(0deg, rgba(217, 86, 16, 0.95), rgba(217, 86, 16, 0.95));
  text-align: center;
  padding-top: 65px;
}
.ecolution-blob p {
    line-height: 1.3em;
  }
.active-offers__main-content {
  display: flex;
  flex-direction: column;
  margin: var(--sdds-spacing-element-24) var(--sdds-spacing-element-48);
}

.salesman-offerings {
  background: var(--sdds-grey-100);
  margin: 0px -48px;
  padding: 24px 48px 48px;
}
.company-information {
  padding-top: 44px;
}
.spinner {
  margin-top: 16px;
  height: inherit;
}
.no-active-offers, .no-filtered-offers {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@media only screen and (max-width: 1366px) {
  .active-offers__card-list {
    margin-top: var(--sdds-spacing-element-16) !important;
  }
  .active-offers__card-list > * {
    width: 47%;
    margin: 0;
  }
}
@media only screen and (max-width: 1294px) {
  .ecolution-blob {
    right: 0px;
  }
  .is-there-blob {
    margin-top: var(--sdds-spacing-layout-72) !important;
  }
}
@media only screen and (max-width: 1024px) {
  .active-offers__card-list > * {
    margin: unset;
  }
}
@media only screen and (max-width: 768px) {
  .sdds-paragraph-01 {
    font-size: 5rem;
  }
  .sdds-expressive-headline-01 {
    font-size: 12rem;
  }
  .active-offers__header > div {
    width: 80%;
    margin: var(--sdds-spacing-element-24) var(--sdds-spacing-element-16);

  }
  .active-offers__main-content {
    margin: var(--sdds-spacing-element-24) var(--sdds-spacing-element-16);
  }
  .salesman-offerings {
    margin: 0px 0px;
  }
  .ecolution-blob {
    width: 160px !important;
    height: 160px !important;
    bottom: -100px;
    padding-top: 2.7em;
  }
  .ecolution-blob h2 {
    font-size: 1.9em;
  }
  .ecolution-blob p {
    font-size: 0.9em;
  }
  .active-offers__card-list > * {
    width: 100%;
    margin: 0;
  }
}
@media only screen and (max-width: 650px) {
  .ecolution-blob {
    bottom: -140px;
  }
}
</style>
