<script setup>
import { inject, onMounted, ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useMainStore } from '../store';
import RegulationsApproval from '../components/RegulationsApproval';
import ContactDetails from '../components/ContactDetails';
import { removeUserResetPasswordMapping } from '../services/digitalSalesService';
import { addCustomTrackingEvent } from '../utils/customTracking';

const router = useRouter();
const keycloak = inject('keycloak');
const adobeEvent = inject('adobeEvent');
const store = useMainStore();

const requiredRole = process.env.VUE_APP_DS_KEYCLOAK_REQUIRED_ROLE;
const requiredSalesmanRole = process.env.VUE_APP_DS_KEYCLOAK_REQUIRED_SALESMAN_ROLE;

const hasRole = keycloak.hasResourceRole(requiredRole) || keycloak.hasResourceRole(requiredSalesmanRole);
const isAuthorized = keycloak.authenticated && hasRole;
const noOffersAvailable = ref(false);
const errorLoadingOffers = ref(false);
const notAllowedToSeeOffer = ref(false);
const adminDsUserMissing = ref(false);
const offerExpired = ref(false);
const isOfferNotValid = ref(false);
const isError = ref(false);
const loading = ref(true);
const loadDataError = computed(() => store.offerLoadDataError);

onBeforeMount(() => {
  store.activePage = 'HOME';
});

onMounted(() => {
  loading.value = true;
  isError.value = loadDataError.value || checkUser() || false ;
  loading.value = false;
  adobeEvent({
    event: 'pageView',
    page: {
      pageName: 'HomeView',
      offerId: store.offerId,
      regulationsApproved: store.offerStatus.regulationsApprovedForUser,
      error: {
        isErrorPage: isError.value,
        data: {
          adminDsUserMissing: adminDsUserMissing.value,
          hasRole,
          isAuthorized,
          noOffersAvailable: noOffersAvailable.value,
          notAllowedToSeeOffer: notAllowedToSeeOffer.value,
          errorLoadingOffers: errorLoadingOffers.value,
          offerExpired: offerExpired.value,
          ...(isOfferNotValid.value ? { isOfferNotValid: true } : {}),
        },
      },
    },
  });
  addCustomTrackingEvent('homeView');
});
const checkUser = () => {
  if(!hasRole && store.isAdmin) {
    adminDsUserMissing.value = true;
    return true;
  }

  if(!isAuthorized) return true;

  if(store.offerId && store.offerStatus.expired) {
    offerExpired.value = true;
    return true;
  }
  if(store.offerId && store.offerStatus.isOfferNotValid) {
    isOfferNotValid.value = true;
    return true;
  }
  if(!store.offerStatus.regulationsApprovedForUser) {
    removeUserResetPasswordMapping(store.federationUserAccountId).catch(err => {
      console.error('failed to remove user from reset password mapping: ', err);
    });
    return;
  }

  router.push({ name: 'ConfiguratorView', params: { market: store.market }, query: { id: store.offerId }});
};

const proceedToConfigurator = () => {
  router.push({ name: 'ConfiguratorView', params: { market: store.market }, query: { id: store.offerId }});
};

const proceedToActive = () => {
  store.offerLoadDataError = false;
  router.push({ name: 'ActiveOffersView', params: { market: store.market }});
};


</script>
<template>
  <section>
    <template v-if="adminDsUserMissing && !loading">
      <h1>Missing role</h1>
      <p>ds-user is missing</p>
    </template>
    <template v-else-if="!loading">
      <template v-if="!isAuthorized">
        <h1>Road closed</h1>
        <p>You do not have access to this page</p>
        <a
          href="https://my.scania.com"
          class="sdds-btn sdds-btn-primary"
          data-dd-action-name="go to MyScania"
        >
          Go to MyScania
        </a>
      </template>
      <template v-else-if="!hasRole">
        <h1>{{ $i18n('roadClosed') }}</h1>
        <p>{{ $i18n('restrictedAccess') }}</p>
        <a
          href="https://my.scania.com"
          class="sdds-btn sdds-btn-primary"
          data-dd-action-name="go to MyScania"
        >
          {{ $i18n('goToMyScania') }}
        </a>
      </template>
      <template v-else-if="offerExpired">
        <h1>{{ $i18n('offerExpired') }}</h1>
        <p>{{ $i18n('offerExpiredText') }}</p>
        <ContactDetails />
      </template>
      <template v-else-if="isOfferNotValid">
        <h1>{{ $i18n('offerNotValid') }}</h1>
        <p>{{ $i18n('offerNotValidText') }}</p>
        <ContactDetails />
      </template>
      <template v-else-if="store.offerId && !store.offerStatus.regulationsApprovedForUser && !isError">
        <RegulationsApproval
          @approved="store.offerStatus.regulationsApprovedForUser = true; proceedToConfigurator();"
        />
      </template>
      <template v-else-if="loadDataError">
        <h1>{{ $i18n('roadClosed') }}</h1>
        <p>{{ $i18n('loadDataError') }}</p>
        <a
          class="sdds-btn sdds-btn-primary"
          data-dd-action-name="go to active page"
          @click="proceedToActive()"
        >
          {{ $i18n('goBackToAvailableOffers') }}
        </a>
      </template>
    </template>
  </section>
</template>

<style scoped>
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
  }
  @media only screen and (max-width: 500px) {
    section {
      padding: 1em;
    }
  }
</style>
