<script setup>
import { inject, onMounted, onBeforeMount } from 'vue';
import ContactDetails from '../components/ContactDetails';
import { useMainStore } from '../store/index.js';
import { addCustomTrackingEvent } from '../utils/customTracking';

const adobeEvent = inject('adobeEvent');

const store = useMainStore();

onBeforeMount(() => {
  store.activePage = 'CONFIRMATION';
});

onMounted(() => {
  adobeEvent({
    event: 'pageView',
    page: {
      pageName: 'ConfirmationView',
      offerId: store.offerId,
    },
  });
  addCustomTrackingEvent('confirmationView');
});
</script>

<template>
  <div class="confirmation">
    <h1>{{ $i18n('confirmationViewHeading') }}</h1>
    <p>{{ $i18n('confirmationViewText') }}</p>
    <ContactDetails />
  </div>
</template>

<style scoped>
  .confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    /* flex: 0 1 1024px; */
    /* flex-wrap: wrap; */
  }
</style>
