<script setup>
/* eslint-disable vue/no-v-html */
import { ref, computed, inject, onBeforeMount, onMounted } from 'vue';
import { useMainStore } from '../store/index.js';
import { useConfiguratorApi } from '../composables/configurator';
import { useRouter } from 'vue-router';
import { finalizeOffer, updateSelections, addOfferComment, getOfferComments } from '../services/digitalSalesService.js';
import SummaryItemGroup from '../components/SummaryItemGroup';
import SummaryLeasingItem from '../components/SummaryLeasingItem';
import SummaryDetailItem from '../components/SummaryDetailItem.vue';
import NumberInput from '../components/NumberInput.vue';
import ImageSlider from '../components/SimpleImageSwiper.vue';
import { addCustomTrackingEvent } from '../utils/customTracking';
import SummaryItemIcon from '@/components/SummaryItemIcon.vue';
import VehicleNumberInput from '@/components/VehicleNumberInput.vue';
import che_down from '@/assets/chevron_down.svg';
import che_up from '@/assets/chevron_up.svg';
import PageSpinner from '../components/PageSpinner';


const { totalPrice, totalFormattedPrice, buyBackValue, repairMaintenanceBasePrice } = useConfiguratorApi();
const i18n = inject('i18n');
const adobeEvent = inject('adobeEvent');

const store = useMainStore();
const router = useRouter();

const finalizationError = ref(false);
const finalizationProcessing = ref(false);
const contactMeError = ref(false);
const contactMeProcessing = ref(false);
const existingCustomerComments = ref([]);
const customerComments = ref('');

const filteredSelections = computed(() => {
  const wantedKeys = Object.keys(store.selected).filter(key => !['leasing', 'defaultOptionsNotDisplayed'].includes(key));
  return Object.fromEntries(wantedKeys.map(key => [key, store.selected[key]]));
});

const leasingSelections = computed(() => {
  return store.selected.leasing;
});

const showBuyBackValue = computed(() => {
  return store.market === 'pl';
});

const interestingInfoTotalCost = computed(() => {
  if (store.selectedPaymentType === 'cash') {
    const returnObj = {
      cost: { description: `${totalFormattedPrice.value.totalPriceMultVehicles} ${i18n(totalFormattedPrice.value.priceSuffix)}` },
      monthlyCostForServices: { description: `${totalFormattedPrice.value.monthlyPriceMultVehicles} ${i18n(totalFormattedPrice.value.priceSuffixMonthly)}` },
      downPaymentSubheaderTitle: totalFormattedPrice.value.cashDepositPriceMultVehicles ? { description: `${totalFormattedPrice.value.cashDepositPriceMultVehicles} ${i18n(totalFormattedPrice.value.priceSuffix)}` } : { description: '' },
    };
    if (store.showYearEndOfferBanner) {
      const yearEndOfferPriceMultVehicles = ((totalPrice.value.monthlyPrice - repairMaintenanceBasePrice.value + 1) * store.selectedNumberVehicles).toLocaleString('en').replace(/,/g," ");
      returnObj.monthlyCostForServices = {
        description: `${yearEndOfferPriceMultVehicles}#${i18n(totalFormattedPrice.value.priceSuffixMonthly)}`,
        customDescription: true,
        additionalDescription: `${totalFormattedPrice.value.monthlyPriceMultVehicles} ${i18n(totalFormattedPrice.value.priceSuffixMonthly)} ${i18n('yearEndOfferPriceHelperText2')}`,
      };
    }
    return returnObj;
  }
  const returnObj = {
    cost: { description: `${totalFormattedPrice.value.totalPriceMultVehicles} ${i18n(totalFormattedPrice.value.priceSuffix)}*` },
  };
  if (store.showYearEndOfferBanner) {
    const yearEndOfferPriceMultVehicles = ((totalPrice.value.price - repairMaintenanceBasePrice.value + 1) * store.selectedNumberVehicles).toLocaleString('en').replace(/,/g," ");
    returnObj.cost = {
      description: `${yearEndOfferPriceMultVehicles}#${i18n(totalFormattedPrice.value.priceSuffix)}*`,
      customDescription: true,
      additionalDescription: `${totalFormattedPrice.value.totalPriceMultVehicles} ${i18n(totalFormattedPrice.value.priceSuffix)}* ${i18n('yearEndOfferPriceHelperText2')}`,
    };
  }
  return returnObj;
});

const itemIconHeaders = computed(() => {
  const items = [{
    icon: 'clock',
    title: 'plannedDeliveryDate',
    value: store.misc?.deliveryDate,
  }];
  if (store.selectedPaymentType === 'cash') {
    items.push({
      icon: 'truck',
      title: 'cost',
      value: `${totalFormattedPrice.value.price} ${i18n(totalFormattedPrice.value.priceSuffix)}`,
    });
    if (store.showYearEndOfferBanner) {
      const yearEndOfferPrice = (totalPrice.value.monthlyPrice - repairMaintenanceBasePrice.value + 1).toLocaleString('en').replace(/,/g," ");
      items.push({
        icon: 'calendar',
        title: 'monthlyCostForServices',
        value: `${yearEndOfferPrice}#${i18n(totalFormattedPrice.value.priceSuffix)}*`,
        customValue: true,
      });
    } else {
      items.push({
        icon: 'calendar',
        title: 'monthlyCostForServices',
        value: `${totalFormattedPrice.value.monthlyPrice} ${i18n(totalFormattedPrice.value.priceSuffixMonthly)}`,
      });
    }
    items.push({
      icon: 'star',
      title: 'downPaymentSubheaderTitle',
      value: totalFormattedPrice.value.cashDepositPrice ? `${totalFormattedPrice.value.cashDepositPrice} ${i18n(totalFormattedPrice.value.priceSuffix)}` : '',
    });
  } else {
    if (store.showYearEndOfferBanner) {
      const yearEndOfferPrice = (totalPrice.value.price - repairMaintenanceBasePrice.value + 1).toLocaleString('en').replace(/,/g," ");
      items.push({
        icon: 'truck',
        title: 'cost',
        value: `${yearEndOfferPrice}#${i18n(totalFormattedPrice.value.priceSuffix)}*`,
        customValue: true,
      });
    } else {
      items.push({
        icon: 'truck',
        title: 'cost',
        value: `${totalFormattedPrice.value.price} ${i18n(totalFormattedPrice.value.priceSuffix)}*`,
      });
    }
    if(showBuyBackValue.value){
      items.push({
        icon: 'star',
        title: 'buyBackValue',
        value: `${buyBackValue.value}`,
      });
    }
  }
  return items;
});

const titleOffer = computed(() => (store.misc?.vehicleCardHighlightsHeader) || (store.misc?.template && store.misc?.template === `${String(store.market).toUpperCase()}_STANDARDA4X2EB` ? i18n('offerExtraLowChassisHeader') : i18n('offerNormalChassisHeader')));
const title2 = computed(() => store.misc?.vehicleCardHighlightsHeader2);

const slideImages = computed(() => {
  return [
    {
      src: store.images.side,
      alt: "side",
    },
    {
      src: store.images.view,
      alt: "view",
    },
    {
      src: store.images.front,
      alt: "front",
    },
  ];
});

const isVisibleTextArea = ref(false);

let debounceTimer = null;
const debounceSelect = (selectObject) => {
  clearTimeout(debounceTimer);
  const currentValue = leasingSelections.value[selectObject.type].id;
  if (currentValue === selectObject.option.id) return;
  debounceTimer = setTimeout(() => {
    adobeEvent({
      event: 'leasingOptionsSelectionChange',
      leasingSelection: {
        label: selectObject.type,
        id: selectObject.option.id,
        value: selectObject.option.value,
      },
    }),
      updateSelections(store.selected).then(res => {
      }).catch(err => {
        console.error('update selections error: ', err);
      });
  }, 1500);
};

const getSliderData = (valueId) => {
  const id = valueId.split('-')[0];
  return store.options.leasing.find(x => x.id === id);
};

const fetchOfferComments = async () => {
  const res = await getOfferComments();
  if (res.data && res.data[0]?.customerComments) existingCustomerComments.value = res.data;
  return;
};

const contactMe = async () => {
  contactMeError.value = false;
  contactMeProcessing.value = true;
  const offerComment = { customerComments: customerComments.value };
  const result = await addOfferComment(offerComment)
    .catch(error => {
      console.error(error);
      contactMeError.value = true;
      contactMeProcessing.value = false;
    });
  if (!result) return;

  if (result.status !== 200) {
    console.error('failed to send customer comments');
    contactMeError.value = true;
    contactMeProcessing.value = false;
    return;
  }
  addCustomTrackingEvent('customerContactMe');
  adobeEvent({ event: 'customerContactMe' });
  store.addSuccessToast(i18n('contactMeSuccessMessage'));
  await fetchOfferComments();
  contactMeProcessing.value = false;
  customerComments.value = '';
};

const finalizeOrder = async () => {
  finalizationError.value = false;
  finalizationProcessing.value = true;
  const finalizationData = {
    totalCost: totalPrice.value,
    selections: store.selected,
    selectedNumberVehicles: store.selectedNumberVehicles,
  };

  if (customerComments.value)
    finalizationData.customerComments = customerComments.value;

  const result = await finalizeOffer(finalizationData)
    .catch(error => {
      console.error(error);
      finalizationError.value = true;
      finalizationProcessing.value = false;
    });
  if (!result) return;

  if (result.status !== 200) {
    console.error('failed to finalize offer');
    finalizationError.value = true;
    finalizationProcessing.value = false;
    return;
  }
  addCustomTrackingEvent('orderSent');

  adobeEvent({ event: 'orderSent' });
  store.offerStatus.finalized = true;

  router.push({ name: 'ConfirmationView', params: { market: store.market }, query: { id: store.offerId } });
};

const goToConfiguration = () => {
  router.push({ name: 'ConfiguratorView', params: { market: store.market }, query: { id: store.offerId } });
};

const setFeatureToggle = async () => {
  store.showYearEndOfferBanner = false;
  if (store.misc?.specType && store.misc?.specType === 'stock_vehicle') {
    const response = store.featureToggleData[`${store.market}#webapp_yearEndOfferBanner`];
    if (response) {
      const { isActive, isEffectiveUser, isFeatureDateValid } = response;
      store.showYearEndOfferBanner = isActive && isEffectiveUser && isFeatureDateValid;
    }
  }
};

onBeforeMount(() => {
  store.activePage = 'SUMMARY';
  setFeatureToggle();
  adobeEvent({
    event: 'pageView',
    page: {
      pageName: 'SummaryView',
      offerId: store.offerId,
    },
  });
  addCustomTrackingEvent('summaryView');
  if (store.selectedPaymentType === 'leasing' && store.selected.leasing.serviceContractLength) {
    delete store.selected.leasing.serviceContractLength;
    updateSelections(store.selected).then(res => {
    }).catch(err => {
      console.error('update selections error: ', err);
    });
  }
});

onMounted(async () => await fetchOfferComments());

const updateTextareaInput = (input) => customerComments.value = input.target.value;

</script>

<template>
  <template v-if="!store.options || store.appLoading">
    <PageSpinner />
  </template>
  <template v-else>
    <div class="sdds-container padding-top-16">
      <div
        v-if="finalizationError"
        class="sdds-row"
      >
        <div class="sdds-col-lg-12">
          <div
            class="sdds-banner sdds-banner-error"
            @click="finalizationError = false"
          >
            <div class="sdds-banner-body">
              <h6 class="sdds-banner-header">
                {{ $i18n('finalizeOrderError') }}
              </h6>
            </div>

            <div class="sdds-banner-close" />
          </div>
        </div>
      </div>
      <div class="sdds-row">
        <div class="sdds-col-lg-12">
          <span class="go-back-configurator-view">
            <svg
              class="el-margin-right-8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.046 5.685a1 1 0 0 0-1.414-1.415l-9.9 9.9a2.6 2.6 0 0 0 0 3.678l9.9 9.9a1 1 0 1 0 1.415-1.415L4.722 17.01h24.306a1 1 0 0 0 0-2H4.722l9.325-9.324Z"
                fill="currentColor"
              />
            </svg>
            <a
              data-analytics="{'eventComponent':'summaryPage','eventType':'link','eventLabel':'go back to configuration'}"
              data-dd-action-name="go back to configuration"
              @click="goToConfiguration"
            >{{ $i18n('goBackToConfiguration') }}
            </a>
          </span>
        </div>
      </div>
      <div class="sdds-row margin-top-16">
        <div class="sdds-col-lg-12 sdds-col-xs-12">
          <p
            class="sdds-headline-03 main-tittle-summary"
            v-html="$i18n('yourNewScaniaIsReadyToOrder')"
          />
        </div>
      </div>
      <div class="white-container">
        <div class="sdds-row">
          <div
            v-for="(item, key) in itemIconHeaders"
            :key="key"
            class="sdds-col-lg-3 sdds-col-md-6 sdds-col-xs-12"
          >
            <SummaryItemIcon
              :icon="item.icon"
              :title="item.title"
              :value="item.value"
              :custom-value="item.customValue"
            />
          </div>
        </div>
        <template v-if="store.selectedPaymentType === 'leasing'">
          <div class="sdds-row">
            <div
              v-for="(item, key) in leasingSelections"
              :key="key"
              class="sdds-col-lg-4 sdds-col-md-6 sdds-col-xs-12"
            >
              <summary-leasing-item
                :header="`${key}SubheaderTitle`"
                :description="item.description"
                :slider-data="getSliderData(item.id)"
                :show-slider-tooltip-value="key === 'downPayment'"
                :slider-tooltip-value="key === 'downPayment' ? `${totalPrice.downPaymentPrice.toLocaleString('en').replace(/,/g, ' ')} ${$i18n(totalPrice.priceSuffixOneTime)}` : null"
                @select="debounceSelect"
              />
            </div>
          </div>
        </template>
      </div>
      <div class="sdds-row margin-top-16 section-container">
        <div class="swiper swiper-section image-section image-container">
          <ImageSlider :slides="slideImages" />
        </div>
        <div class="summary-section">
          <div class="action-container">
            <h3 class="tittle-summary">
              {{ $i18n('summaryTitleActionOrder') }}
            </h3>
            <p>
              {{ $i18n('summaryDescriptionActionOrder') }}
            </p>
            <div class="sdds-row interesting-info-mult-vehicles-row">
              <div class="sdds-col-lg-12 sdds-col-md-12 sdds-col-xs-12">
                <VehicleNumberInput
                  v-model="store.selectedNumberVehicles"
                  header="numberOfVehicles"
                  :min="1"
                  :max="store.misc.allowedNumberOfVehicles"
                  :image-url="store.images.front"
                  :title="titleOffer"
                  :title2="title2"
                />
              </div>
            </div>
            <div
              class="sdds-row interesting-info-mult-vehicles-row padding-left-32 summary-card-total summary-card-total-media"
            >
              <div class="sdds-col-lg-6 sdds-col-md-6 sdds-col-xs-12">
                <h3 class="total-description">
                  {{ $i18n('total') }}
                </h3>
              </div>
              <div
                v-for="(item, key) in interestingInfoTotalCost"
                :key="key"
                class="sdds-col-lg-6 sdds-col-md-6 sdds-col-xs-12"
              >
                <summary-detail-item
                  :header="key"
                  :description="item.description"
                  :custom-description="item.customDescription"
                  :additional-description="item.additionalDescription"
                  monthly-price-tooltip-id="monthly-price-tooltip-hero-section"
                />
              </div>
            </div>
            <div class="sticky-button-bar">
              <div class="sdds-row center-align-content margin-top-16">
                <div class="sdds-col-lg-12 comments-textarea-container">
                  <div
                    class="comments-bar-title"
                    @click="isVisibleTextArea=!isVisibleTextArea"
                  >
                    <p>
                      {{ $i18n('customerCommentsLabel') }}
                    </p>
                    <span
                      class="comments-icon"
                    >
                      <img
                        v-if="isVisibleTextArea"
                        :src="che_down"
                      >
                      <img
                        v-if="!isVisibleTextArea"
                        :src="che_up"
                      >
                    </span>
                  </div>
                  <sdds-textarea
                    :class="{
                      'show-comment-area' : isVisibleTextArea,
                      'hidde-comment-area' : !isVisibleTextArea
                    }"
                    :value="customerComments"
                    rows="4"
                    class="sdds-col-lg-12 sdds-u-pl0 sdds-on-white-bg comments-area"
                    :disabled="contactMeProcessing"
                    @input="updateTextareaInput"
                  />
                </div>
              </div>
              <div class="sdds-row center-align-content margin-top-16">
                <div class="sdds-col-lg-12 sdds-col-md-12  sdds-col-xs-12 action-button-container">
                  <button
                    class="sdds-btn sdds-btn-secondary sdds-btn-fullbleed"
                    :disabled="!customerComments || contactMeProcessing || store.isSalesman"
                    data-dd-action-name="contact me"
                    @click="contactMe()"
                  >
                    {{ $i18n('contactMeButtonText') }}
                    <font-awesome-icon
                      v-if="contactMeProcessing"
                      icon="spinner"
                      class="fa-spin"
                    />
                  </button>
                  <button
                    class="sdds-btn sdds-btn-primary sdds-btn-fullbleed"
                    :disabled="finalizationProcessing || contactMeProcessing || store.isSalesman"
                    data-dd-action-name="finalize order"
                    @click="finalizeOrder()"
                  >
                    {{ $i18n('finalizeOrderButtonNewText') }}
                    <font-awesome-icon
                      v-if="finalizationProcessing"
                      icon="spinner"
                      class="fa-spin"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sdds-row margin-top-16">
        <div class="sdds-col-lg-12 sdds-col-xs-12 accordion-section">
          <sdds-accordion>
            <sdds-accordion-item
              :header="$i18n('summaryTitleItemSelections')"
              affix="prefix"
              tabindex="1"
            >
              <summary-item-group
                :selections="filteredSelections"
                class="accordion-section-item"
              />
            </sdds-accordion-item>
          </sdds-accordion>
        </div>
        <div
          v-if="existingCustomerComments.length"
          class="sdds-col-lg-12 sdds-col-xs-12 accordion-section"
        >
          <sdds-accordion>
            <sdds-accordion-item
              :header="$i18n('customerPreviousCommentsLabel')"
              affix="prefix"
              tabindex="1"
            >
              <div
                v-for="comment in existingCustomerComments"
                :key="comment.sk"
                class="sdds-row center-align-content margin-top-16"
              >
                <div class="sdds-col-lg-12 sdds-col-xs-12">
                  <sdds-textarea
                    :value="comment.customerComments"
                    class="sdds-col-lg-12 sdds-col-xs-12 sdds-u-pl0"
                    :disabled="true"
                  />
                </div>
              </div>
            </sdds-accordion-item>
          </sdds-accordion>
        </div>
      </div>
      <div class="sdds-row center-align-content margin-top-16">
        <div class="sdds-col-lg-4" />
        <div class="sdds-col-lg-4">
          <h2 class="center-align-content">
            {{ $i18n('whatHappensNext') }}
          </h2>
        </div>
        <div class="sdds-col-lg-4" />
      </div>
      <div class="sdds-row margin-top-16">
        <div class="sdds-col-lg-2 text-right process__step-number">
          <label class="process__step-number-label">1</label>
        </div>
        <div class="sdds-col-lg-8 process__step-text">
          <p v-if="store.selectedPaymentType === 'leasing'">
            {{ $i18n('whatHappensNextText1') }}
          </p>
          <p v-if="store.selectedPaymentType === 'cash'">
            {{ $i18n('whatHappensNextCashText1') }}
          </p>
        </div>
        <div class="sdds-col-lg-2" />
      </div>
      <div class="sdds-row margin-top-16">
        <div class="sdds-col-lg-2 text-right process__step-number">
          <label class="process__step-number-label">2</label>
        </div>
        <div class="sdds-col-lg-8 process__step-text">
          <p v-if="store.selectedPaymentType === 'leasing'">
            {{ $i18n('whatHappensNextText2') }}
          </p>
          <p v-if="store.selectedPaymentType === 'cash'">
            {{ $i18n('whatHappensNextCashText2') }}
          </p>
        </div>
        <div class="sdds-col-lg-2" />
      </div>
      <div
        v-if="finalizationError"
        class="sdds-row"
      >
        <div class="sdds-col-lg-12">
          <div
            class="sdds-banner sdds-banner-error"
            @click="finalizationError = false"
          >
            <div class="sdds-banner-body">
              <h6 class="sdds-banner-header">
                {{ $i18n('finalizeOrderError') }}
              </h6>
            </div>
            <div class="sdds-banner-close" />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>


<style scoped>
.error-container {
  display: flex;
  place-content: center;
}

.error-container>.sdds-banner {
  width: 100%;
}

img {
  width: 30%;
}


.center-align-content {
  text-align: center;
}

.text-right {
  text-align: right;
}


.process__step-number {
  display: flex;
  place-content: center;
}

.process__step-number-label {
  font-size: 5em;
  font-weight: 600;
  color: var(--sdds-blue-100);
  margin-top: 0.2em;
}

span>svg {
  width: 5rem;
  vertical-align: bottom;
}

/* allow multiple vehicles */

.interesting-info-mult-vehicles-row {
  font-size: 5rem;
  font-weight: 500;
}

.swiper-slide>img {
  width: 90%;
}

.total-description {
  margin-bottom: 0;
}

.summary-card {
  border: 1px solid var(--sdds-grey-300);
  border-radius: 1rem;
  padding: var(--sdds-spacing-layout-8) var(--sdds-spacing-layout-16);
  background-color: var(--sdds-white);
  text-align: left;
  padding-bottom: 0;
  font-weight: 600;
}

.swiper {
  max-width: 100%;
  display: flex;
  align-items: center;
}

.summary-card-total {
  border: 1px solid var(--sdds-grey-300);
  border-radius: 1rem;
  padding: var(--sdds-spacing-layout-8) var(--sdds-spacing-layout-16);
  background-color: var(--sdds-grey-200);
  text-align: left;
  margin-top: var(--sdds-spacing-layout-8);
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 600;
}

.comments-textarea-container {
  padding: 0;
  width: 100%;
}

.comments-textarea-container>sdds-textarea {
  padding: 0;
}

.action-button-container {
  display: flex;
  justify-content: space-evenly;
}

.white-container {
  background-color: var(--sdds-white);
  border: 1px solid var(--sdds-grey-300);
  border-radius: 0.5rem;
}

.action-container {
  height: 100%;
  width: 90%;
  padding-bottom: 5rem;
}

.summary-section {
  background-color: var(--sdds-white);
  border: 1px solid var(--sdds-grey-300);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  flex:1;
}

.image-section {
  background-color: var(--sdds-grey-50);
  border: 1px solid var(--sdds-grey-300);
  border-radius: 0.5rem;
  flex:1;
}

.image-container {
  flex: 1;
  padding: 5rem 5rem;
}

.interesting-info-mult-vehicles-row>div {
  padding: 0;
}

sdds-accordion-item:focus {
  outline: unset
}

.accordion-section {
  padding-bottom: 2rem;
}

.section-container{
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.comments-bar-title{
  display: flex;
}

.comments-icon {
  display: none;
  visibility: hidden;
}

.main-tittle-summary{
  color: var(--sdds-grey-600);
}

.white-container > div:nth-child(2) {
  padding-bottom: 5rem;
}

.go-back-configurator-view {
  cursor: pointer;
}

@media only screen and (max-width: 320px) {
  .summary-card-total-media {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 480px) {

  .comments-icon {
    display: flex;
    margin-left: auto;
    padding: 2rem 3rem;
    visibility: visible;
  }

  .comments-icon > img {
    width: 100%;
  }

  .sticky-button-bar {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: var(--sdds-white);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2.6px;
    border: 1px solid var(--sdds-grey-400);
    z-index: 10;
    padding: 0 var(--sdds-spacing-layout-8);
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .sticky-button-bar > div {
    margin-top: 0;
  }

  .action-button-container{
    width: 100%;
    gap: 5rem;
    padding-bottom: 3rem;
  }

  .comments-area :deep(.sdds-textarea-container) {
    height: 30vh;
  }

  .accordion-section-item{
    min-width: 85vw;
  }

  .hidde-comment-area{
    display: none;
    visibility: hidden;
  }

  .show-comment-area{
    display: block;
    visibility: visible;
  }

  .action-button-container > .sdds-btn {
    height: 15rem;
    padding: var(--sdds-spacing-element-16);
  }

  .comments-bar-title{
    text-align: left;
    font-family: "Scania Sans", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: calc(((16/ 4)) * 1rem);
    line-height: calc(((24/ 4)) * 1rem);
    letter-spacing: -0.02em;
  }

  .comments-bar-title > p {
    font-size: calc(((16/ 4)) * 1rem);
    line-height: calc(((24/ 4)) * 1rem);
  }
}


@media only screen and (max-width: 767px) {
  .summary-card-total-media {
    font-size: 5rem;
  }

  .tittle-summary {
    font-size: 7rem;
  }
  .main-tittle-summary{
    font-size: 5rem;
  }
}

@media only screen and (max-width: 992px) {
  .white-container {
    margin-left: 0;
  }
  .swiper-section {
    flex-basis: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1184px) {
  .summary-card-total-media {
    font-size: 5rem;
  }

  .swiper-slide>img {
    width: 60%;
  }

  .swiper-section {
    flex-basis: 100%;
  }

  .summary-section {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>

<style>

.interesting-info-mult-vehicles-row>div:nth-child(1) div.item-description {
  border-bottom: 1rem solid var(--sdds-blue-400)
}

.white-container > div:nth-child(1) > div:nth-child(1) > .item-container > .content > span {
  border-bottom: 1rem solid var(--sdds-blue-400)
}

.main-tittle-summary > h3 {
  font-family: 'Scania Sans Headline', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: calc(((40/ 4)) * 1rem);
  line-height: calc(((40/ 4)) * 1rem);
  letter-spacing: 0em;
  margin: 0;
  color: var(--sdds-grey-958);
}
</style>
